.partner-card {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  max-width: 30rem;
  border-radius: 10%;
}

.voucher-code {
  font-size: 1rem;
}

.copy-icon {
  font-size: 1rem;
  margin-left: 0.2rem;
  cursor: pointer;
}

.copy-icon:hover {
  color: var(--ion-color-tertiary);
}

img {
  cursor: pointer;
}

a:link {
  color: var(--ion-color-tertiary);
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: var(--ion-color-tertiary);
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: var(--ion-color-tertiary);
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: var(--ion-color-tertiary);
  background-color: transparent;
  text-decoration: underline;
}
