.map-container {
  position: relative;
  overflow: hidden;
  padding-top: 75%; /* Adjust this value depending on the desired aspect ratio of your map */
}

.map-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
