.about-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.about-paragraph {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  text-align: justify;
}

.about-link {
  color: #007bff;
  text-decoration: none;
}

.about-link:hover {
  text-decoration: underline;
}

.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
