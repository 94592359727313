.description {
  white-space: break-spaces;
}

.card-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 600px) {
  .card-container {
    flex-direction: column;
  }
}
